::-webkit-scrollbar {
  width: 12px;
  height: 4px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #49aae8;
  border: 0px none #ffffff;
}
::-webkit-scrollbar-thumb:hover {
  background: #0979e2;
}
::-webkit-scrollbar-thumb:active {
  background: #4961e8;
}
::-webkit-scrollbar-track {
  background: #adc0ff94;
  border: 0px none #ffffff;
}
::-webkit-scrollbar-track:hover {
  background: #add7ff94;
}
::-webkit-scrollbar-track:active {
  background: #add7ff94;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

html,
body {
  overflow-x: hidden !important;
  display: unset;
  background-color: #ecf0f1;
  /* color: aquamarine; */
  scroll-behavior: smooth;
}
.bg-white {
  background-color: #ffffff !important;
}

/* .particle-bg {
  position: absolute;

  width: 100vw;
  height: 100vh;
}
.tsparticles canvas {
  position: absolute !important;

} */
/* .top-bar {
  background-image: linear-gradient(90deg, #5fccff 10%, #4aaeff 100%);
} */

.map-card {
  background-color: rgba(2, 14, 26, 0.349);
  border-radius: 20px;
  filter: saturate(0.1);
}

.map-card:hover {
  filter: saturate(1.3);
}
.img-small {
  height: 50px !important;
  width: 50px !important;
}
/* .ser-img-box {
  height: 50px !important;
  width: 190px !important;
  background-color: aqua;
} */
.services-wrap {
  padding-top: 10px;
}
.serv-2:hover {
  /* background-color: #1bc5b700; */
  transition: all 0.3s ease-in-out;
  transform: translatex(10px);
}
.canvas-class {
  position: absolute;
  top: 0;
  left: 0;
}

.brand-card-sm {
  height: 80px;
  width: 100px;
  margin: 10px;
  /* padding: 10px; */
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #b1b1b171;
  transition: all 0.3s ease-in-out;
}
.brand-card-sm:hover {
  box-shadow: 2px 5px 30px rgba(0, 0, 0, 0.411);
}
.brand-sm-img {
  height: auto;
  width: 80px;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}

.color-teal {
  color: #1881fa !important;
}
.brand-sub-menu {
  position: relative;
  left: 60% !important;
}
.center-pos {
  position: relative;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
}

.capStyle option {
  text-transform: uppercase;
}

@media only screen and (min-width: 2000px) {
  .home-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.orange-text {
  color: #ffa500 !important;
}

/* TODO: TO remove This  */
.sp-link {
  color: #49aae8;
}
.sp-link:hover {
  color: #ffffff;
}
.backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.page-404 {
  background-color: #ecf0f1;
  height: 80vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-site-card {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #b1b1b171;
  transition: all 0.3s ease-in-out;
  padding: 50px;
}
.chk-nov {
  height: 50px;
  padding: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  border-radius: 15px;
  background-color: #ff7300 !important;
}
.f04-e-page {
  min-height: 40vh;
}

.dreamy-bg {
  background: rgb(32, 101, 209);
  background: linear-gradient(
    38deg,
    rgb(0, 98, 255) 0%,
    rgb(90, 241, 255) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
.home-title-sec {
  text-align: center;
}
.ht-main {
  font-size: 56px;
  font-weight: bolder;
  letter-spacing: 3px;
}
.ht-sub {
  margin-top: 25px;
  font-size: 23px;
  line-height: 40px;
  font-weight: bolder;
  color: #033857;
}
@media only screen and (min-width: 768px) {
  .ht-main {
    font-size: 86px;
    letter-spacing: 3px;
  }
  .ht-sub {
    margin-top: 45px;
    margin-bottom: 18px;
    font-size: 36px;
    line-height: 40px;
    font-weight: bolder;
  }
}

.btm-title {
  font-size: 18px;
  font-weight: bolder;
  color: #033857;
}
.home-title-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
