// Font Family
// $bodyFont: 'Livvic', sans-serif;
// $titleFont: 'Livvic', sans-serif;

$bodyFont: "sf_pro_display_regular", "Work Sans", sans-serif;
$titleFont: "sf_pro_display_bold", "Work Sans", sans-serif;

// Colors
$bodyColor: #444444;
$primaryColor: #106eea;
$secondaryColor: #03228f;
$purpleColor: #787bf2;
$titleColor: #033857;
$titleColor2: #ffffff;
$titleColor3: #eef2f5;
$whiteColor: #ffffff;
$bodyColor: #454545;
$bodyColor2: #363636;
$graybg: #f6f7f9;
$secbg: #f9f9f9;
$blueShadow: #0168bd1a;
$backgroundMain: #17b8a2;
$backgroundMain2: #049984;
$cardColor: #fff;

//Dark Mode Colors

$darkBodyColor: #101010;
$darkBGColor1: #082269;
$darkBGColor2: #021c2b;
$darkBGColor3: #1f2022;
$darkTextBlue: #16b0f7;
$darkTextWhite: #ffffff;
$darkTextGray: #8fa9db;
$darkTextBlue2: #022f49;

// New Variables
$orangeColor: #f2b150;

$homeColor1: #ecf0f1;
$homeColor2: #ffffff;
$homeTextColor1: #49aae8; //main color , primary color
$homeTextColor2: #f8b600;
$textColor1: #49aae8;
$textColor2: #033857;
$cardColor: #fff;
$cardColor2: #033857;
$homeTitleColor1: #49aae8;
$homeTitleColor2: #06183f;
$homeCardColor: #49aae8; // Brand Color banner in home page
$footer-bg-home: #000000;
$navActiveColor: #000000;
$navColor: #000000;

//
//Home card colors
$cardColor3: #4ed310;
$cardColor4: #0584ce;
$cardColor5: #ffee00;
$cardColor6: #439aec;
$cardColor7: #d8244b;
$cardColor8: #ff7b00;
$cardColor9: #dd0000;
$cardColor10: #38acf0;
//Counter Colors
$counterColor1: #2ca05c;
$counterColor2: #3872f0;

$animLineColor1: #006eff;
$animLineColor2: #09b4df;

// $topHeaderGradient: linear-gradient(to right, #17b8a2, #049984);
// $headerGradient: linear-gradient(to right, #17b8a2, #049984);

$topHeaderGradient: linear-gradient(to right, #49aae8, #49aae8);
$headerGradient: linear-gradient(to right, #49aae8, #49aae8);

// Gradient Backgrounds
$gradient-bg: linear-gradient(180deg, $secondaryColor 0%, $primaryColor 100%);

// Common
$transition: all 0.3s ease;

// Responsive Variables
$laptop: "only screen and (max-width: 1600px)";
$xl: "only screen and (max-width: 1366px)";
$lg: "only screen and (max-width: 1199px)";
$only_lg: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md: "only screen and (max-width: 991px)";
$sm: "only screen and (max-width: 767px)";
$xs: "only screen and (max-width: 575px)";
$mobile: "only screen and (max-width: 480px)";

// Removeable --------------------------------------------------------
$white: #ffffff;
$black: #333333;
$grey: #f8f8f8;
$grey-2: #f4f9fc;
$theme-color: #096bd8;
$theme-color-2: #6100b3;
$body-color: #696969;
$hr-border-color: #eceff8;
$footer-bg: #091b29;
$tab-bg: #1a1430;
$tab-color: #aeabba;
$black-soft: #7e7e7e;
$link-color: #e4ecf3;
$border-color: #eaedff;
$heading-color: #333333;

$c_reg: "sf_pro_display_regular";
$c_med: "sf_pro_display_medium";
$c_sem: "sf_pro_display_bold";
$c_bold: "cerebri_sansbold";
